@import "./_variables";

// $body-bg: var(--white);
// $body-color: var(--dark);

// @import "~bootstrap/scss/bootstrap";
// @import "~@fortawesome/fontawesome-free/css/all";
@font-face {
  font-family: kioskbold;
  src: url("../fonts/Avenir-LT-Std-45-Book_5171.ttf");
}

@font-face {
  font-family: kiosk;
  src: url("../fonts/Avenir-LT-Std-35-Light_5169.ttf");
}

header,
.header {
  background-color: $appColor;
  background-image: url("../images/cercle-dberry.png");
  background-position: top -25px right 1vh;
  background-repeat: no-repeat;
  background-size: 170px;
  background-origin: border-box;
  flex-grow: nowrap;

  // .logo {
  //    max-width: 150px!important;
  // }
  @media (min-width: 992px) {
    flex-flow: row nowrap;
    justify-content: flex-start
  }

  @media (max-width: 469px) {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 40%;
    background-origin: border-box;
  }
}

.header-App-logo {
  max-width: 150px !important;
}

.le-salon-gv {
  // font-size: 30px;
  font-weight: 300;
  color: #fff !important;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 2.5rem;
  display: inline-block;

  strong {
    font-family: kioskbold;
    font-weight: bolder;
  }
}